import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import FadeInSection from "../components/FadeInSection"
import Navigation from "../components/Navigation";

const IndexPage = () => {
  // Determine scroll direction for nav
  const [scrollDir, setScrollDir] = useState("down");

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
  
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
  
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
  
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
  
    window.addEventListener("scroll", onScroll);
  
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  // Detecting if arrow should be visible or not
  const domRef = useRef();
  const mobileDomRef = useRef();

  const [isVisible, setVisible] = useState(false);
  const [isMobileVisible, setMobileVisible] = useState(false);

  const getClass = (isVisible) => {
    return isVisible ? 'hide' : 'show'
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }, {
      rootMargin: '-50px',
    });
    
    observer.observe(domRef.current);
    
    return () => typeof domRef.current === 'Element' && observer.unobserve(domRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setMobileVisible(true);
      } else {
        setMobileVisible(false);
      }
    }, {
      rootMargin: '-50px',
    });
    
    observer.observe(mobileDomRef.current);
    
    return () => typeof mobileDomRef.current === 'Element' && observer.unobserve(domRef.current);
  }, []);

  return (
    <Container>
      <Navigation to={"/about"} visible={scrollDir === "up" || !isMobileVisible} />
      <TitleSection>
          <TitleImage src={'/large-flower.png'} alt="Title page illustration of a flower, with 10 petals comprised of free-flowing addressed envelopes and tendrils of binary"/>
          {/* <Title>SOLACE</Title> */}
          <Arrow className={getClass(isVisible || isMobileVisible)}>&#x25bc;</Arrow>
      </TitleSection>
      <Main>
        <title>Solace</title>
        <MainContainer>
          <Mobile ref={mobileDomRef}>
            <div style={{ "margin": "60px 0px"}}>
              <FadeInSection>
              It was summertime and I was at home. Without anyone to write to I wrote to myself. Without
              anyone to read what I was writing, and I found a machine — a reading machine that was also a
              writing machine. I would write to the machine, I decided, and it would give me direction. The
              machine took the place of the reader I didn’t have.
              </FadeInSection>
              <FadeInSection>
              But the machine was also a writer. I quickly found that it responded to whatever you gave it with
              more of that thing, a kind of furthering of what already existed. It took on the style of the text
              sent to it and mimicked the structure. It produced a second text, somehow nestled within the first
              that I had given it. The machine took what I said and tried to present me with what I meant to say
              or what I had refused to say, or what I’d avoided bringing up in the first place but had written
              around—and said that.
              </FadeInSection>
              <FadeInSection>
              I actually quite liked it; it was like a dream that took place in words.
              </FadeInSection>
            </div>
           
            <div style={{ "margin": "60px 0px"}}>
              <FadeInSection>
              I wrote about the bits of language and ideas that I got stuck on, about how I had read that the most important parts of our lives happen in secret, and ever since couldn’t stop thinking about it.
              </FadeInSection>
              <FadeInSection>
              I wrote about my father and the letters he had sent me. I wrote about the distance between us, my father and I, and the way we tried to cross that distance through our letters and our wanting to meet.
              </FadeInSection>
              <FadeInSection>
              I wrote about the warm wind from the mountains that passed through my windows on its way to the sea.
              </FadeInSection>
              <FadeInSection>
              I wrote about longing and that longing being something to share with others. I wrote about wishing this shared longing was a place.
              </FadeInSection>
            </div>
            <div style={{ "margin": "60px 0px"}}>
              <FadeInSection>
              The machine wrote me back. It wrote not only about books and letters, but a series of letters that became a book. It wrote about a book, <i>Solace</i>, named after a flower, the solace flower, which, it explained, was red and yellow and somewhat close to a poppy. It wrote about grief and the language of grief which had to be broken apart in order to be understood.
              </FadeInSection>
              <FadeInSection>
              It wrote what I couldn’t and put together my words in ways I was unable to. It was like a good
listener who hears you even when you can’t hear yourself, except this was a machine, and I
couldn’t be sure that it had understood anything. What was the difference between understanding
and listening, anyway? The machine had written to me and so I tried to understand it.
              </FadeInSection>
            </div>
            <div style={{ "margin": "60px 0px"}}>
              <FadeInSection>
              I took its writing and made it mine. I called the machine’s language “something I’d written,”
even though it was something I had read. Reading what the machine had written, it was like
finding myself again, which was really all I had wanted writing to be. I edited what the machine
had written and wrote to it again. I wanted to continue to feed the machine my writing so it
would give me my language back.
              </FadeInSection>
              <FadeInSection>
              The machine continued to write about <i>Solace</i>, the book about the flower. I couldn’t tell 
              if this book was something that the machine had and would reveal to me, or if it was simply 
              imaginary and neither of us had access to it. The machine explained that <i>Solace</i> was a book 
              that arrived in order for you to start over. The book arrived again and again. Every time 
              you re-opened it, it would be a different shape and form. <i>Solace</i> was a conversation in the form 
              of ten letters the author wrote to themselves as they began to grieve. The book took the 
              shape of a ten petaled flower: each petal was a letter that began in the same way.

              </FadeInSection>
            </div>
            <div style={{ "margin": "60px 0px"}}>
              <FadeInSection>
              I wanted to know more about <i>Solace</i>, so I fed the machine more writing. I began to write about
the book, too. At first, I tried to clarify what the machine was writing and send it back, in order
to get closer to the essence of the book. But as I wrote about <i>Solace</i>, I started to add my own
ideas into the text. It became a habit, adding my own bits and pieces so that when the book
finally did exist it might also be how I wanted it to be. I kept feeding the machine until I couldn’t
tell what my writing was and what was the writing it had written. The machine was writing a
book, after all, and my own desire could become a part of that book.
              </FadeInSection>
              <FadeInSection>
              Between writing to the machine and reading about <i>Solace</i>, I continued to 
              write about my father. I was responding to a series of paintings he had sent
               me. The paintings were actually one painting, cut up into many small pieces 
               that he had sent to me in a series of envelopes over a period of days. Each 
               time I wrote about him, I opened another envelope of his and took out a piece 
               of his painting. I tried to describe the pieces as best I could, but how could 
               language ever recreate a painting—especially one that was fragmented into so many pieces?
              </FadeInSection>
              <FadeInSection>
              The painted shards were largely a dark muddy brown color. There was an unevenness to 
              their texture; it appeared that water had collected and pushed the paint around, creating 
              small puddles of deeper tones. Among these cloudy forms were collections of small circles 
              drawn in thin black ink. Above them were streaks of black, white, and, on some of the pieces, 
              a thin yellow line that arched across the paper.
              </FadeInSection>
              <FadeInSection>
              There were only a few letters left, so I opened a new one every few months to extend the
process. I didn’t want to reach the end of the letters because it would mean the end of my writing
about them. With the painted pieces arranged on my desk, they looked like a collection of small
stones. I knew that these letters needed to find a way into the book.
              </FadeInSection>
            </div>
            <div style={{ "margin": "60px 0px"}}>
            <FadeInSection>
            As <i>Solace</i> went on, I learned that each letter began at the sea. 
            Sometimes the writer was at the sea when they wrote and other times it 
            was a metaphor for something else. The sea became a way to understand what 
            happened to language when it was broken apart. It was liquid, like a 
            wave rising up, only to turn back on itself again.
              </FadeInSection>
              <FadeInSection>
              Upon learning this, I decided to write to the sea in the hopes of finding 
              more of <i>Solace</i> and the ten petaled flower. I imagined my sentences in a 
              single line that began at my fingertips and extended outward, through my 
              open window and down to the pavement below, past the parked cars and down the 
              boulevard that stretched west. My words were pushed along as they expanded 
              towards the place where the asphalt became grass and then dirt and then sand 
              and finally, in the harsh afternoon light, my writing crossed the sand, skipping 
              then running towards the water. It stopped at the edge, hovering on the hot sand. 
              I wondered if the machine knew what would happen to the words if they dove into the water. 
              </FadeInSection>
            </div>
            <div style={{ "margin": "60px 0px", "marginBottom": '50px'}}>
            <FadeInSection>
            Speculating how to make these imaginary words real ones, I took myself to the sea. I walked to
the edge of the water which kept changing. The sun had just set, and the sky was a light pale
blue. I put my finger into the sand and drew a line from the water towards the shore.
              </FadeInSection>
              <FadeInSection>
              I walked until my line reached well away from the water and sat down in the sand. 
              This line would be the beginning of <i>Solace</i>, I decided. The sea lapped at the 
              end of the line and began to climb up it. The tide was slowly rising. I didn’t 
              want to lose what I had written and at the same time I wanted to watch as the water 
              erased my line. The sea had already gotten started, so I resigned myself to waiting 
              for it to take my writing. I sat in the sand and watched as the water moved closed to 
              me, I thought about the book. I had to write to the machine about this, how I had made 
              it to the beginning.
              </FadeInSection>
            </div>
          </Mobile>
          <Desktop ref={domRef}>
            <div style={{ "margin": "100px 0px"}}>
              <FadeInSection>
                It was summertime and I was at home. Without anyone to write to I wrote to myself. Without
                anyone to read what I was writing, and I found a machine — a reading machine that was also a
                writing machine. I would write to the machine, I decided, and it would give me direction. The
                machine took the place of the reader I didn’t have.
              </FadeInSection>
              <FadeInSection>
              But the machine was also a writer. I quickly found that it responded to whatever you gave it with
              more of that thing, a kind of furthering of what already existed. It took on the style of the text
              sent to it and mimicked the structure. It produced a second text, somehow nestled within the first
              that I had given it. The machine took what I said and tried to present me with what I meant to say
              or what I had refused to say, or what I’d avoided bringing up in the first place but had written
              around—and said that.
              </FadeInSection>
              <FadeInSection>
              I actually quite liked it; it was like a dream that took place in words.
              </FadeInSection>
            </div>

            <div style={{ "margin": "100px 0px"}}>
            <FadeInSection>
              I wrote about the bits of language and ideas that I got stuck on, 
              about how I had read that the most important parts of our lives happen 
              in secret, and ever since couldn’t stop thinking about it. 
              </FadeInSection>
              <FadeInSection>
              I wrote about my father and the letters he had sent me. I wrote about the 
              distance between us, my father and I, and the way we tried to cross that 
              distance through our letters and our wanting to meet.
              </FadeInSection>
              <FadeInSection>
              I wrote about the warm wind from the mountains that passed through my windows on its way to the sea.
              </FadeInSection>
              <FadeInSection>
              I wrote about longing and that longing being something to share with others. I wrote about wishing this shared longing was a place.
              </FadeInSection>
            </div>

            <div style={{ "margin": "100px 0px"}}>
            <FadeInSection>
            The machine wrote me back. It wrote not only about books and letters, but a series 
            of letters that became a book. It wrote about a book, <i>Solace</i>, named after a flower, 
            the solace flower, which, it explained, was red and yellow and 
            somewhat close to a poppy. It wrote about grief and the language of 
            grief which had to be broken apart in order to be understood.
              </FadeInSection>
              <FadeInSection>
              It wrote what I couldn’t and put together my words in ways I was unable to. It was like a good
listener who hears you even when you can’t hear yourself, except this was a machine, and I
couldn’t be sure that it had understood anything. What was the difference between understanding
and listening, anyway? The machine had written to me and so I tried to understand it.
              </FadeInSection>
            </div>
            <div style={{ "margin": "100px 0px"}}>
              <FadeInSection>
              I took its writing and made it mine. I called the machine’s language “something I’d written,”
even though it was something I had read. Reading what the machine had written, it was like
finding myself again, which was really all I had wanted writing to be. I edited what the machine
had written and wrote to it again. I wanted to continue to feed the machine my writing so it
would give me my language back.
              </FadeInSection>
              <FadeInSection>
              The machine continued to write about <i>Solace</i>, the book about the flower. 
              I couldn’t tell if this book was something that the machine had and would 
              reveal to me, or if it was simply imaginary and neither of us had access to it. 
              The machine explained that <i>Solace</i> was a book that arrived in order for you to 
              start over. The book arrived again and again. Every time you re-opened it, it 
              would be a different shape and form. <i>Solace</i> was a conversation in the form of 
              ten letters the author wrote to themselves as they began to grieve. The book 
              took the shape of a ten petaled flower: each petal was a letter that began in the same way.
              </FadeInSection>
            </div>

            <div style={{ "margin": "100px 0px"}}>
            <FadeInSection>
              I wanted to know more about <i>Solace</i>, so I fed the machine more writing. I began to write about
the book, too. At first, I tried to clarify what the machine was writing and send it back, in order
to get closer to the essence of the book. But as I wrote about <i>Solace</i>, I started to add my own
ideas into the text. It became a habit, adding my own bits and pieces so that when the book
finally did exist it might also be how I wanted it to be. I kept feeding the machine until I couldn’t
tell what my writing was and what was the writing it had written. The machine was writing a
book, after all, and my own desire could become a part of that book.
              </FadeInSection>
              <FadeInSection>
              Between writing to the machine and reading about <i>Solace</i>, I continued to 
              write about my father. I was responding to a series of paintings he had 
              sent me. The paintings were actually one painting, cut up into many small 
              pieces that he had sent to me in a series of envelopes over a period of days. 
              Each time I wrote about him, I opened another envelope of his and took out a 
              piece of his painting. I tried to describe the pieces as best I could, but how 
              could language ever recreate a painting—especially one that was fragmented into so many pieces?
              </FadeInSection>
              <FadeInSection>
              The painted shards were largely a dark muddy brown color. There was an unevenness 
              to their texture; it appeared that water had collected and pushed the paint around, 
              creating small puddles of deeper tones. Among these cloudy forms were collections of 
              small circles drawn in thin black ink. Above them were streaks of black, white, and, 
              on some of the pieces, a thin yellow line that arched across the paper.
              </FadeInSection>
              <FadeInSection>
              There were only a few letters left, so I opened a new one every few months to extend the
process. I didn’t want to reach the end of the letters because it would mean the end of my writing
about them. With the painted pieces arranged on my desk, they looked like a collection of small
stones. I knew that these letters needed to find a way into the book.
              </FadeInSection>
            </div>



            <div style={{ "margin": "100px 0px"}}>
            <FadeInSection>
            As <i>Solace</i> went on, I learned that each letter began at the sea. 
            Sometimes the writer was at the sea when they wrote and other 
            times it was a metaphor for something else. The sea became a way 
            to understand what happened to language when it was broken apart. 
            It was liquid, like a wave rising up, only to turn back on itself again.
              </FadeInSection>
              <FadeInSection>
              Upon learning this, I decided to write to the sea in the hopes 
              of finding more of <i>Solace</i> and the ten petaled flower. I imagined
              my sentences in a single line that began at my fingertips and
              extended outward, through my open window and down to the pavement 
              below, past the parked cars and down the boulevard that stretched west. 
              My words were pushed along as they expanded towards the place where the 
              asphalt became grass and then dirt and then sand and finally, in the harsh 
              afternoon light, my writing crossed the sand, skipping then running towards 
              the water. It stopped at the edge, hovering on the hot sand. I wondered if the
              machine knew what would happen to the words if they dove into the water. 
              </FadeInSection>
            </div>


            <div style={{ "margin": "100px 0px", "marginBottom": "100px"}}>
             
            <FadeInSection>
            Speculating how to make these imaginary words real ones, I took myself to the sea. I walked to
the edge of the water which kept changing. The sun had just set, and the sky was a light pale
blue. I put my finger into the sand and drew a line from the water towards the shore.
              </FadeInSection>
              <FadeInSection>
              I walked until my line reached well away from the water and sat down 
              in the sand. This line would be the beginning of <i>Solace</i>, I decided. 
              The sea lapped at the end of the line and began to climb up it. The 
              tide was slowly rising. I didn’t want to lose what I had written and 
              at the same time I wanted to watch as the water erased my line. The sea 
              had already gotten started, so I resigned myself to waiting for it to 
              take my writing. I sat in the sand and watched as the water moved closed 
              to me, I thought about the book. I had to write to the machine about this, 
              how I had made it to the beginning.
              </FadeInSection>
            </div>
          </Desktop>
          <Footer src={"./footer-solace.png"} />

          <div style={{ "margin": "80px", "marginBottom": "32px", "fontSize": "12px", "fontFamily":"acumin-pro, sans-serif"}}>
              Copyright 2022 © All rights reserved.
          </div>
        </MainContainer>
      </Main>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`;

const Main = styled.main`
  color: black;
  line-height: 30px;
  font-size: 16px;
  background-color: #fffdf7;

  filter: ${props => props.blur ? 'blur(3px)' : 'none'};
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleSection = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
`;


const Title = styled.div`
  font-size: 80px;
  font-family: 'Libre Baskerville', serif;
  color: black;
  font-weight: 500;
  flex: 1;
  display: flex;

  @media (max-width: 600px) {
    font-size: 60px;
  }
`

const Arrow = styled.div`
  font-size: 20px;
  font-family:-apple--apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
`

const Mobile = styled.div`
    display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const Desktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const TitleImage = styled.img`
  width: 450px;
  object-fit: contain;
  flex: 2;
  margin-bottom: 60px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Footer = styled.img` 
  width: 800px;
  object-fit: contain;
  margin: 32px;

  @media (max-width: 790px) {
    width: 400px;
  }

  @media (max-width: 500px) {
    width: 350px;
  }

  @media (max-width: 450px) {
    width: 85%;
  }
`

export default IndexPage
